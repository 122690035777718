import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import PasscodePage from './pages/passcodePage';
import ImagePage from './pages/imagePage';
import ImagePage2 from './pages/imagePage2';
import ImagePage3 from './pages/imagePage3';
import SurveyPage from './pages/survey_page';
import ThankYouPage from './pages/thanks_page';
import axios from 'axios';

axios.defaults.baseURL = process.env.NODE_ENV === 'production'
 ? 'http://survey.cs.oberlin.edu'
 : 'http:/localhost:5000';

const App = () => {
  const [usedFaces, setUsedFaces] = useState(new Set());
  const [pageIndex, setPageIndex] = useState(0);
  const [formData, setFormData] = useState({
    selectedOption: "",
    selectedSubgroup: "",
    selectedImages: {
      page1: [],
      page2: [],
      page3: []
    },
    face: "", // Face variable keeps track of who is taking the survey and assures their face is not displayed
    order: [], // Array to store the image page order
    gender: "",
    genderSpecify: "",
    race: "",
    raceSpecify: "",
    age: "",
    name: "",
    email: "",
    feedback: "",
    dateTime: ""
  });


  const handleSubmit = async (updatedFormData) => {
      const currentDateTime = new Date().toISOString();
    
      updatedFormData = {
        ...formData,
        ...updatedFormData,
        dateTime: currentDateTime,
      };
    
      try {
        const response = await axios.post('https://survey.cs.oberlin.edu/api/save', updatedFormData);
        console.log('Save successful:', response.data);
      } catch (error) {
        console.error('Save error:', error);
        
        // Optional: Display a message to the user or log additional details
        alert("An error occurred while saving. Please try again.");
      }
    };
  ///*
  const handleNext = () => {
    if (pageIndex < formData.order.length - 1) {
      setPageIndex(pageIndex + 1);
      navigate(`/${formData.order[pageIndex + 1]}`);
    }
  };

  const handlePrevious = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
      navigate(`/${formData.order[pageIndex - 1]}`);
    }
  };
  //*/

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PasscodePage
              setFormData={setFormData}
              formData={formData}
              usedFaces={usedFaces}
              setUsedFaces={setUsedFaces}

            />
          }
        />

        {/* Dynamically render pages based on the order in formData */}
        {formData.order.length > 0 && (
          <>
            <Route
              path="/image"
              element={
                <ImagePage
                  setFormData={setFormData}
                  formData={formData}
                  usedFaces={usedFaces}
                  setUsedFaces={setUsedFaces}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              }
            />
            <Route
              path="/image2"
              element={
                <ImagePage2
                  setFormData={setFormData}
                  formData={formData}
                  usedFaces={usedFaces}
                  setUsedFaces={setUsedFaces}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              }
            />
            <Route
              path="/image3"
              element={
                <ImagePage3
                  setFormData={setFormData}
                  formData={formData}
                  usedFaces={usedFaces}
                  setUsedFaces={setUsedFaces}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              }
            />
          </>
        )}

        <Route
          path="/survey"
          element={
            <SurveyPage
              formData={formData}
              setFormData={setFormData}
              handleSubmit={handleSubmit}
              usedFaces={usedFaces}
              setUsedFaces={setUsedFaces}
            />
          }
        />
       <Route 
          path="/thank_you"
          element={
            <ThankYouPage />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

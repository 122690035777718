import React, { useState } from 'react';
import './imageGrid.css';

const ImageGrid = ({ images, onSelectImage, rows, columns }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  // Ensure 'images' is always an array
  const validImages = Array.isArray(images) ? images : [];

  const handleImageClick = (index) => {
    setSelectedImage(index);
    onSelectImage(validImages[index]);
  };

  return (
    <div
      className="grid-container"
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, auto)`, // Fixed typo: 'gridTemplaterows' to 'gridTemplateRows'
      }}
    >
      {validImages.map((src, index) => (
        <div
          key={index}
          className={`grid-item ${selectedImage === index ? 'selected' : ''}`}
          onClick={() => handleImageClick(index)}
        >
          <img src={src} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;

import React, { useEffect, useState, useRef } from 'react';
import ImageGrid from '../components/imageGrid.js';
import { useNavigate } from 'react-router-dom';
import './imagePage.css';
import getRandomImages from '../components/getRandomImages.js';

const ImagePage = ({ setFormData, formData, usedFaces, setUsedFaces, pageIndex, setPageIndex }) => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState("");
  const [images, setImages] = useState([]);
  const usedFacesRef = useRef(usedFaces);

  // Update ref whenever usedFaces changes
  useEffect(() => {
    usedFacesRef.current = usedFaces;
  }, [usedFaces]);

  // Load random images based on selectedOption and selectedSubgroup
  useEffect(() => {
    const { selectedOption, selectedSubgroup, face } = formData;
    const otherOptions = ['OC', 'UMD', 'ICRC'].filter(option => option !== selectedOption);
    const randomImages = getRandomImages(selectedOption, selectedSubgroup, face, otherOptions, 12, 3, usedFacesRef.current, setUsedFaces, formData);
    setImages(randomImages);
  }, [formData.selectedOption, formData.selectedSubgroup, usedFaces, setUsedFaces]);

  const handleNext = () => {
    if (selectedImage) {
      const isCorrect = selectedImage.includes(formData.selectedSubgroup);
      setFormData(prevData => {
        const updatedData = {
          ...prevData,
          selectedImages: {
            ...prevData.selectedImages,
            [`page${pageIndex + 1}`]: { url: selectedImage, correct: isCorrect }
          }
        };
  
        // Navigate only after formData is fully updated
        if (pageIndex < formData.order.length - 1) {
          setPageIndex(pageIndex + 1);
          navigate(`/${formData.order[pageIndex + 1].toLowerCase()}`);
        } else {
          navigate('/survey'); // Go to survey if no more image pages
        }
        return updatedData;
      });
    } else {
      alert('Please select an image.');
    }
  };

  const handlePrevious = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
      navigate(`/${formData.order[pageIndex - 1].toLowerCase()}`);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Choose the image of another member from the {formData.selectedSubgroup} department</h1>
      <div className="image-grid-wrapper">
        <ImageGrid images={images} onSelectImage={setSelectedImage} rows={4} columns={3} />
      </div>
      {/* <button onClick={handlePrevious} disabled={pageIndex === 0}>Back</button> */}
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default ImagePage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  display: grid;
  gap: 20px; /* Increased gap between grid items */
}

.grid-item {
  position: relative;
  border: 5px solid #f8f8f8; /* Off-white border */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); /* Small shadow */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  overflow: hidden; /* Ensure content does not overflow */
  min-height: 300px; /* Set a minimum height for each grid item */
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  justify-content: space-between; /* Ensure image and text take up available space */
}

.grid-item img {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: border 0.5s, box-shadow 0.3s;
  display: block; /* Remove bottom space below images */
  flex-grow: 1; /* Allow image to grow to fill available space */
}

.grid-item.selected {
  border-color: blue; /* Blue border when selected */
}

.grid-item .image-text {
  padding: 5px;
  background-color: #f8f8f8; /* Same off-white background for the text area */
  text-align: center;
  font-size: 14px;
  white-space: pre-wrap;
  box-sizing: border-box; /* Include padding in element's total width */
}
`, "",{"version":3,"sources":["webpack://./src/components/imageGrid.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS,EAAE,qCAAqC;AAClD;;AAEA;EACE,kBAAkB;EAClB,yBAAyB,EAAE,qBAAqB;EAChD,0CAA0C,EAAE,iBAAiB;EAC7D,sBAAsB,EAAE,mEAAmE;EAC3F,gBAAgB,EAAE,qCAAqC;EACvD,iBAAiB,EAAE,4CAA4C;EAC/D,aAAa;EACb,sBAAsB,EAAE,oCAAoC;EAC5D,8BAA8B,EAAE,kDAAkD;AACpF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,wCAAwC;EACxC,cAAc,EAAE,qCAAqC;EACrD,YAAY,EAAE,gDAAgD;AAChE;;AAEA;EACE,kBAAkB,EAAE,8BAA8B;AACpD;;AAEA;EACE,YAAY;EACZ,yBAAyB,EAAE,gDAAgD;EAC3E,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,sBAAsB,EAAE,6CAA6C;AACvE","sourcesContent":[".grid-container {\n  display: grid;\n  gap: 20px; /* Increased gap between grid items */\n}\n\n.grid-item {\n  position: relative;\n  border: 5px solid #f8f8f8; /* Off-white border */\n  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); /* Small shadow */\n  box-sizing: border-box; /* Include padding and border in element's total width and height */\n  overflow: hidden; /* Ensure content does not overflow */\n  min-height: 300px; /* Set a minimum height for each grid item */\n  display: flex;\n  flex-direction: column; /* Stack image and text vertically */\n  justify-content: space-between; /* Ensure image and text take up available space */\n}\n\n.grid-item img {\n  width: 100%;\n  height: auto;\n  cursor: pointer;\n  transition: border 0.5s, box-shadow 0.3s;\n  display: block; /* Remove bottom space below images */\n  flex-grow: 1; /* Allow image to grow to fill available space */\n}\n\n.grid-item.selected {\n  border-color: blue; /* Blue border when selected */\n}\n\n.grid-item .image-text {\n  padding: 5px;\n  background-color: #f8f8f8; /* Same off-white background for the text area */\n  text-align: center;\n  font-size: 14px;\n  white-space: pre-wrap;\n  box-sizing: border-box; /* Include padding in element's total width */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-grid-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 30px; /* Add some padding to the left and right to prevent the ImageGrid from touching the edges */
  }
  
  .image-grid-wrapper > div {
    width: 70%; /* This will make the ImageGrid take up one-third of the wrapper's width */
  }`, "",{"version":3,"sources":["webpack://./src/pages/imagePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe,EAAE,4FAA4F;EAC/G;;EAEA;IACE,UAAU,EAAE,0EAA0E;EACxF","sourcesContent":[".image-grid-wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    padding: 0 30px; /* Add some padding to the left and right to prevent the ImageGrid from touching the edges */\n  }\n  \n  .image-grid-wrapper > div {\n    width: 70%; /* This will make the ImageGrid take up one-third of the wrapper's width */\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

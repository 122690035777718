import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const SurveyPage = ({ formData, setFormData, handleSubmit }) => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    gender: "",
    genderSpecify: false,
    race: "",
    raceSpecify: false,
    age: "",
    feedback: ""
  });
  const [confirmed, setConfirmed] = useState(false);

  // Ref to track if navigation has occurred
  const hasNavigatedRef = useRef(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (name) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: !prevValues[name],
      [name === 'genderSpecify' ? 'gender' : 'race']: prevValues[name] ? prevValues[name === 'genderSpecify' ? 'gender' : 'race'] : ""
    }));
  };

  const handleNext = (e) => {
    e.preventDefault();

    // Check if already navigated
    if (!confirmed && !hasNavigatedRef.current) {
      setConfirmed(true);
      hasNavigatedRef.current = true; // Set the ref to prevent future navigation
      handleSubmit(formValues);
      navigate('/thank_you');
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Please give us your feedback.</h1>
      <form onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <label>
          In as much or as little detail as you prefer, please describe your gender:
        </label>
        <textarea 
          name="gender" 
          value={formValues.gender} 
          onChange={handleChange} 
          placeholder="Please specify" 
          style={{ width: "300px", height: "100px", marginBottom: "10px", backgroundColor: formValues.genderSpecify ? "#d3d3d3" : "white" }}
          disabled={formValues.genderSpecify}
        ></textarea>
        <label>
          Prefer not to say
          <input 
            type="checkbox" 
            name="genderSpecify" 
            checked={formValues.genderSpecify} 
            onChange={() => handleCheckboxChange("genderSpecify")}
          />
        </label>

        <label>
          In as much or as little detail as you prefer, please describe your race and/or ethnicity:
        </label>
        <textarea 
          name="race" 
          value={formValues.race} 
          onChange={handleChange} 
          placeholder="Please specify" 
          style={{ width: "300px", height: "100px", marginBottom: "10px", backgroundColor: formValues.raceSpecify ? "#d3d3d3" : "white" }}
          disabled={formValues.raceSpecify}
        ></textarea>
        <label>
          Prefer not to say
          <input 
            type="checkbox" 
            name="raceSpecify" 
            checked={formValues.raceSpecify} 
            onChange={() => handleCheckboxChange("raceSpecify")}
          />
        </label>

        <label>
          What is your age in years?
        </label>
        <select name="age" value={formValues.age} onChange={handleChange} style={{ width: "300px", marginBottom: "10px" }}>
          <option value="">Select...</option>
          <option value="18-24">18-24</option>
          <option value="25-34">25-34</option>
          <option value="35-44">35-44</option>
          <option value="45-54">45-54</option>
          <option value="55-64">55-64</option>
          <option value="65-74">65-74</option>
          <option value="75 or older">75 or older</option>
          <option value="Prefer not to say">Prefer not to say</option>
        </select>
        
        <label>Please let us know if you have any feedback about any part of the survey.</label>
        <textarea 
          name="feedback" 
          value={formValues.feedback} 
          onChange={handleChange} 
          placeholder="Feedback" 
          style={{ width: "300px", height: "100px", marginBottom: "10px" }}
        ></textarea>
      </form>
      <div>
        <button type="button" onClick={handleNext} disabled={confirmed}>Submit</button>
      </div>
    </div>
  );
};

export default SurveyPage;
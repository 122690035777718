import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import loginData from '../../login_data.json';
import './passcodePage.css';

const PasscodePage = ({ setFormData, formData, usedFaces, setUsedFaces }) => {
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState("");
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const navigate = useNavigate();

  const handlePasscodeSubmit = () => {
    const data = loginData[passcode];
    if (data) {
      // Update formData with organization and other info
      setFormData(prevFormData => ({
        ...prevFormData,
        selectedSubgroup: data.selectedSubgroup,
        selectedOption: data.selectedOption,
        face: data.face,
        order: data.order
      }));
      setShouldNavigate(true); // Trigger navigation flag
      console.log("selected Option: ", data.selectedOption, "selected Subgroup: ", data.selectedSubgroup, "face: ", data.face, "order: ", data.order);
    } else {
      setError("Invalid passcode");
    }
  };

  useEffect(() => {
    // Navigate only when the formData is updated
    if (shouldNavigate && formData.order && formData.order.length > 0) {
      console.log('formDatas order: ', formData.order);
      console.log('formDatas selectedSubgroup: ', formData.selectedSubgroup)
      console.log('formDatas selectedOption:', formData.selectedOption)
      navigate(`/${formData.order[0]}`);
    }
  }, [formData, shouldNavigate, navigate]);

  return (
    <div>
      <h1>Enter Password</h1>
      <input
        type="text"
        value={passcode}
        onChange={(e) => setPasscode(e.target.value)}
        placeholder="Enter your passcode"
      />
      <button onClick={handlePasscodeSubmit}>Submit</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default PasscodePage;

//BINGUSMCCHINGUS

import shuffleArray from './shuffleArray.js';

const getRandomImages = (selectedOption, selectedSubgroup, face, otherOptions, numImagesPerPage, perturbationLevel, usedFaces, setUsedFaces, formData) => {

  console.log("updated formData:, ", formData);

  // Ensure usedFaces is initialized as a Set
  if (!usedFaces || !(usedFaces instanceof Set)) {
    throw new Error('usedFaces must be a Set');
  }
  console.log('The complete list of USED FACES is: ')
  console.log(usedFaces)
  
  // function that returns a random item from an array
  const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];
  
  // read json file
  const jsonData = require ("/src/components/organizationInfo.json");
  console.log(jsonData)

  // process json file into hash map
  // - subgroups: department names
  let subgroups = [];
  for (const organization in jsonData){
    const departments = jsonData[organization];
    subgroups.push(...Object.keys(departments));
  }
  console.log('selected subgroups are:', subgroups);

  // contains images to be displayed
  const selectedImages = [];

  // This function returns a counter for getRandomItem()
  // -> from 1 to the number of people in a subgroup
  // assumes: image files are in the correct format
  function createCounter (departmentName) {
    let counter = []
    const org = departmentName.split(" ")[0];
    const lim = jsonData[org][departmentName];
    for (let i = 1; i<lim+1; i++) counter.push(i);
    return counter;
  }
  do{
    // Select one image from the users institution and department. 
    const randomSubgroupImageNumber = getRandomItem(createCounter(selectedSubgroup));
    let selectedSubgroupImage = `/public/images/${selectedOption}/${selectedSubgroup}/${perturbationLevel}/face${randomSubgroupImageNumber}.png`;

    // Ensure the selected image is not already used
    if (!usedFaces.has(`/public/images/${selectedOption}/${selectedSubgroup}/1/face${randomSubgroupImageNumber}.png`)){
      selectedImages.push(selectedSubgroupImage);
      break;
    } else {
      console.log(`Image already used: ${selectedSubgroupImage}`);
    }
  } while (true);

  let attemptCount = 0;
  const maxAttempts = 100;

  // Add the survey participants face to usedFaces
  usedFaces.add(face)
  console.log('added ', face, 'to usedFaces.')

  // New: Select the rest of the images from anywhere else BUT the user's selected institution:
  // while loop runs until there is enough images per page
  while (selectedImages.length < numImagesPerPage){
    // check to make sure it doesnt run endlessly
    attemptCount++;
    if (attemptCount >= maxAttempts) {
      console.error('Exceeded maximum attempts to select unique images');
      break;
    }
    // find a random subgroup that is not the selectedSubgroup:
    const randomSubgroup = getRandomItem(subgroups.filter(subgroup => subgroup !== selectedSubgroup));
    // find the subgroup's ogranization:
    // assumes: the format of the department directory name is correct
    const randomSubgroupOrg = randomSubgroup.split(" ")[0];
    // find a random number in range 1->number of faces
    const randomMainImageNumber = getRandomItem(createCounter(randomSubgroup));

    // Define randomImage here
    const randomImage = `/public/images/${randomSubgroupOrg}/${randomSubgroup}/${perturbationLevel}/face${randomMainImageNumber}.png`;
    // This is the path to randomImage with perturbation level 1. This is what is checked against or added to usedFaces. 
    const imageCheck = `/public/images/${randomSubgroupOrg}/${randomSubgroup}/1/face${randomMainImageNumber}.png`; // Keeping all the tracked images have perturbation level 1 for simplicity
    // if the image has already been selected (pushed to selectedImages) or used, skip it
    if (selectedImages.includes(randomImage) || usedFaces.has(imageCheck)) {
      console.log(`Skipped to avoid duplication: ${randomImage}`);
    } else {
      // If the image has not been selected: select!
      selectedImages.push(randomImage);
      usedFaces.add(imageCheck); // Mark the images as used
      // setUsedFaces(new Set(usedFaces)); // Update the state
      console.log(`Selected: ${randomImage}`);
    }
  }
  

  // setUsedFaces(new Set(usedFaces)); // Update the state
  shuffleArray(selectedImages); // Shuffle the images and return them
  return selectedImages;
};

export default getRandomImages;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PasscodePage.css */

body {
    background-color: #f0f8ff;
    font-family: Arial, sans-serif;
    color: #333;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
}

h1 {
    color: #4a90e2;
    margin-bottom: 20px;
}

input {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    font-size: 16px;
    background-color: #fff;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
    outline: none;
}

button {
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    background-color: #4a90e2;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover {
    background-color: #357ab7;
}

button:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button:focus {
    outline: none;
}

.error {
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/pages/passcodePage.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,yBAAyB;AAC5C;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,WAAW;IACX,wCAAwC;IACxC,8CAA8C;AAClD;;AAEA;IACI,qBAAqB;IACrB,2CAA2C;IAC3C,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,eAAe;IACf,wCAAwC;IACxC,kDAAkD;AACtD;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd","sourcesContent":["/* PasscodePage.css */\n\nbody {\n    background-color: #f0f8ff;\n    font-family: Arial, sans-serif;\n    color: #333;\n}\n\n.container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh; /* Full viewport height */\n}\n\nh1 {\n    color: #4a90e2;\n    margin-bottom: 20px;\n}\n\ninput {\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 200px;\n    font-size: 16px;\n    background-color: #fff;\n    color: #333;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    transition: border-color 0.3s, box-shadow 0.3s;\n}\n\ninput:focus {\n    border-color: #4a90e2;\n    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);\n    outline: none;\n}\n\nbutton {\n    padding: 10px 20px;\n    margin-top: 20px;\n    border: none;\n    border-radius: 4px;\n    background-color: #4a90e2;\n    color: #fff;\n    font-size: 16px;\n    cursor: pointer;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    transition: background-color 0.3s, box-shadow 0.3s;\n}\n\nbutton:hover {\n    background-color: #357ab7;\n}\n\nbutton:active {\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n}\n\nbutton:focus {\n    outline: none;\n}\n\n.error {\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import './thanks_page.css';

const ThankYouPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Thank you for taking the time to complete this survey!</h1>
      <h2>If you have any questions, reach out to nwarford@oberlin.edu.</h2>
    </div>
  );
};

export default ThankYouPage;